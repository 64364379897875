import { Injectable } from '@angular/core';
import { Hub, Amplify } from '@aws-amplify/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Auth } from '@aws-amplify/auth'
import { AppStore } from '../stores/app.store';
import { SuperstoreEntity } from '@ChrisAndreasOrg/store-lib';
import { CognitoUser } from '../classes/congito-user.class';
import { Router } from '@angular/router';
import { cognitoResult } from '../classes/cognito-result.class';
import { GrowingMindsStore } from '../stores/growing-minds.store';
import { firstValueFrom } from 'rxjs';
import { CognitoJwtVerifier } from "aws-jwt-verify";
import { JwksCache } from 'aws-jwt-verify/jwk';
import { ComponentService } from './component.service';



@Injectable({
  providedIn: 'root',
})
export class CognitoService {

  verifier: any;
  jwks: JwksCache;
  token: string;
  listener: any;

  constructor(
    private http: HttpClient,
    private appStore: AppStore,
    private router: Router,
    private growingMindsStore: GrowingMindsStore,
    private componentService: ComponentService,
  ) {
    Amplify.configure({
      Auth: environment.cognito
    });
  }

  async getAccessToken() {
    try {
      // This call handles refreshing access and id token if they are expired
      // It will fail if the refresh token is expired, in which case the user is routed to login
      const currentSession = await Auth.currentSession();
      return currentSession.getAccessToken().getJwtToken();
    } catch (err) {
      await  this.router.navigate(['/login']);
      return err;
    }
  }

  async deleteUser(username: string, partition: string, id: string) {
    const body: any = {
      username: username,
      partition: partition,
      id: id
    };

    await firstValueFrom(this.http.post<string>(
      '/api/HttpDeleteUser', body
    ));
    // console.log("done")
  }

  async addSubscriber(


    subscriber: {
      email: string,
      password: string,
      name: string,
      country: string,
      stripeCustomerId: string
    }
  ): Promise<any> {

    try {
      const subscriberReturn = await Auth.signUp({
        username: subscriber.email.replace('@', ''),
        password: subscriber.password,
        attributes: {
          email: subscriber.email,
          name: subscriber.name,
          preferred_username: subscriber.stripeCustomerId, // Stripe customer id
          profile: 'Subscriber', // Role
          zoneinfo: subscriber.country, // stripe country code
        },
        autoSignIn: { enabled: true }
      });

      const returnObject: cognitoResult = { result: 'success', object: subscriberReturn }
      return returnObject;
    }
    catch (err) {
      const returnObject: cognitoResult = { result: 'error', object: err }
      return returnObject;

    }
  }

  async addUser(
    user: {
      username: string,
      subscriberEmail: string,
      password: string,
      name: string,
      role: string,
      country: string,
      stripeCustomerId: string

    }
  ): Promise<any> {

    let email = user.subscriberEmail;
    let username = user.username;
    if (user.username?.includes('@')) {
      // an email has been provided
      email = user.username;
      username = user.username.replace('@', '');

    }

    try {
      const userReturn = await Auth.signUp({
        username: username,
        password: user.password,
        attributes: {
          email: email,
          name: user.name,
          preferred_username: user.stripeCustomerId, // Stripe customer id
          profile: user.role, // ROLE,
          zoneinfo: user.country // stripe country code
        },
      });
      const returnObject: cognitoResult = { result: 'success', object: userReturn }
      return returnObject;

    }
    catch (err) {
      // console.log("MESSAGE",err.message)
      
      // console.log("cognito",err)

      
      const returnObject: cognitoResult = { result: 'error', object: err }
      return returnObject;
    }
  }

  

  async listenToSignInEvent() {

    if (this.listener) {
      this.listener();
    }


   this.listener = Hub.listen('auth', async ({ payload }) => {
      // console.log(payload)
      if (payload.event === 'signIn') {


          const user = payload.data;
          let cognitoUserEntity = this.appStore.CognitoUser.blank();
          await this.updateCognitoUser(cognitoUserEntity, user);
          await this.appStore.CognitoUser.deleteAll();
          await this.appStore.CognitoUser.add(cognitoUserEntity);        
  
        }

        if (payload.event === 'signOut') {     
          }

    });

  }


  // Send confirmation code to user's email
  async forgotPassword(username: string) {
    try {
      const data = await Auth.forgotPassword(username.replace('@', ''));     
      const returnObject: cognitoResult = { result: 'success', object: data }
      return returnObject;
    } 
    catch (err) {
      const returnObject: cognitoResult = { result: 'error', object: err }
      return returnObject;
    }
  };

  // Collect confirmation code and new password
  async forgotPasswordSubmit(username: string, code: string, newPassword: string) {
    try {
      const data = await Auth.forgotPasswordSubmit(username.replace('@', ''), code, newPassword);

      const body: any = {email: username, password: newPassword};
      await firstValueFrom(this.http.post<any>('/api/HttpSetChildrenPassword', body));
      const returnObject: cognitoResult = { result: 'success', object: data }
      return returnObject;
    } catch (err) {
      const returnObject: cognitoResult = { result: 'error', object: err }
      return returnObject;
    }

  };

  async updateJwk() {
    const response = await fetch(environment.awsJWK);
    const jwk = await response.text();
    return jwk;
  }


  async updateCognitoUser(cognitoUserEntity: SuperstoreEntity<CognitoUser, any>, user: any) {
    // add username
    await cognitoUserEntity.update('Username', user.username);

    // add attributes
    if (user.attributes) {

      Object.entries(user.attributes).forEach(async (attribute: [string, string], index: number) => {
        switch (attribute[0]) {
          case 'sub':
            await cognitoUserEntity.update('Id', attribute[1]);
            break;
          case 'name':
            await cognitoUserEntity.update('Name', attribute[1]);
            break;
          case 'email':
            await cognitoUserEntity.update('Email', attribute[1]);
            break;
          case 'profile':
            await cognitoUserEntity.update('AppRole', attribute[1]);
            break;
          case 'preferred_username':
            await cognitoUserEntity.update('GroupId', attribute[1]);
            const group = attribute[1];
            const storageStart = group.indexOf('-s')+2;
            if (storageStart === 1) {
              await cognitoUserEntity.update('Storage', '1');
            } else {
              const storageEnd = group.indexOf("-", storageStart);
              if (storageEnd === -1) {
                await cognitoUserEntity.update('Storage', group.substring(storageStart, group.length));
              } else {
                await cognitoUserEntity.update('Storage', group.substring(storageStart, storageEnd));
              }
            }
            const signalrStart = group.indexOf('-r')+2;
            if (signalrStart === 1) {
              await cognitoUserEntity.update('SignalR', '1');
            } else {
              const signalrEnd = group.indexOf("-", signalrStart);
              if (signalrEnd === -1) {
                await cognitoUserEntity.update('SignalR', group.substring(signalrStart, group.length));
              } else {
                await cognitoUserEntity.update('SignalR', group.substring(signalrStart, signalrEnd));
              }
            }

            // console.log('cognitoUserEntity', cognitoUserEntity);

            break;
          case 'zoneinfo':
            await cognitoUserEntity.update('Country', attribute[1]);
            break;

        }
      });
    }
  }


  async signIn(username: string, password: string): Promise<any> {
    try{    
      let a =  await Auth.signIn(username.replace('@', ''), password);  
      return a
    }
    catch(errorMessage){
      // console.log(errorMessage)
      const returnObject: cognitoResult = { result: 'error', object: errorMessage }
      return returnObject
  
    }

  }

  async signOut() {
    await this.componentService.updateCommon('ParentAndChildCommon', 'userLoggedIn', null);
    localStorage.setItem('last-user-id', null);
    localStorage.setItem('last-user-partition', null);
    await Auth.signOut();
    await this.router.navigate(['/login']);    
  }

  async signOutAuthOnly() {
    await Auth.signOut();
  }


  async getUser(): Promise<any> {

    try{
      const a = await Auth.currentUserInfo(); // this is returning a 400 code and an empty object so is not entering catch code block

      // console.log(a)
      return a 
    }
    catch(err){
      // console.log(err)
    }
    
  }

  async signedIn(): Promise<boolean> {

    let signedIn: boolean;
    try {
      if (Object.keys((await Auth.currentUserInfo())).length > 0) {
        signedIn = true;
        return signedIn
      }
      else {
        signedIn = false;
        return signedIn
      }
    }
    catch {
      signedIn = false;
      return signedIn
    }

  }

async verifyAccessToken(token: string) {
  
// Verifier that expects valid access tokens:
if(!this.verifier)
this.verifier = CognitoJwtVerifier.create({
  userPoolId: environment.cognito.userPoolId,
  tokenUse: "access",
  clientId: environment.cognito.userPoolWebClientId,
});

try {
  const payload = await this.verifier.verify(
  token
  );
  // console.log("Token is valid. Payload:", payload);
  const jwksCache = this.verifier['jwksCache'].jwksCache;
  const jwks: string = JSON.stringify(jwksCache.get(environment.awsJWK));

  return jwks;

} catch {
  this.verifier = null;
  // console.log("Token not valid!");
  return null;
}
}

}